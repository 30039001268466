/* Invitae Design System colors, as documented on the design site:
https://invitae.invisionapp.com/dsm/invitae/invitae/folder/colors/5cb8440900f4df50c8337513
*/

/* Primary Colors
-----------------------------------------------------------------
- The following version of our core brand colors is intended to be primarily used in web executions
  and stems from our core brand colors.
- Deep Sea, Ocean, and Mulberry all meet the WCAG-AA contrast ratio.
- When considering color, ensure heritage colors (Teal, Spring, Ocean, Emerald) are predominant when considering use of both Mulberry and secondary colors.
*/

/* Teal */
$teal: rgb(25, 172, 159);

/* Deep Teal */
$deepTeal: rgb(18, 130, 121);

/* Ocean (AA) */
$ocean: rgb(44, 107, 170);

/* Mulberry (AAA) */
$mulberry: rgb(142, 38, 102);

/* Secondary Colors
-----------------------------------------------------------------
- Secondary colors should be used sparingly to reinforce important actions,
  accent specific components or contents, and provide a sense of place in digital space.
- These should take up less than 20% of the color composition in any web execution.
*/

/* Dandelion */
$dandelion: rgb(241, 200, 93);

/* Spring */
$spring: rgb(155, 202, 110);

/* Emerald */
$emerald: rgb(102, 191, 126);

/* Cobalt (AAA) */
$cobalt: rgb(25, 64, 102);

/* Eggplant (AAA) */
$eggplant: rgb(63, 19, 63);

/* Tints & Shades
-----------------------------------------------------------------
- Each of our primary colors has a base and can be expanded to a range of tints and shades.
- For copy we should use our primary colors and only use tints and shades as an accent to elevate the work.
- If a tint or shade is used for copy, the color must meet WCAG AA contrast ratios.
*/

/* Teal - 5 Tints & Shades
----------------------------------------------------------------- */
/* Sea Foam */
$seaFoam: rgb(236, 248, 247);

/* Bermuda */
$bermuda: rgb(128, 209, 202);

/* Teal */
/* (defined in primary colors) */

/* Deep Sea */
/* (defined in primary colors) */

/* Eden (AAA) */
$eden: rgb(12, 72, 81);

/* Ocean - 5 Tints & Shades
----------------------------------------------------------------- */
/* Catskill */
$catskill: rgb(238, 243, 248);

/* Polo */
$polo: rgb(138, 174, 208);

/* Ocean (AA) */
/* (defined in primary colors) */

/* Cobalt (AAA) */
/* (defined in primary colors) */

/* Cyprus (AAA) */
$cyprus: rgb(17, 42, 68);

/* Mulberry - 5 Tints & Shades
----------------------------------------------------------------- */
/* Prim */
$prim: rgb(246, 237, 243);

/* Viola */
$viola: rgb(192, 135, 171);

/* Mulberry (AAA) */
/* (defined in primary colors) */

/* Port (AAA) */
$port: rgb(99, 26, 88);

/* Eggplant (AAA) */
/* (defined in secondary colors) */

/* Dandelion - 3 Tints & Shades
----------------------------------------------------------------- */
/* Pearl */
$pearl: rgb(252, 246, 230);

/* Buttermilk */
$buttermilk: rgb(247, 224, 166);

/* Dandelion */
/* (defined in secondary colors) */

/* Grays
-----------------------------------------------------------------
- Grays occupy larger areas in applications such as text, backgrounds, icons, lines, and borders.
- Invitae uses grays that have a touch of blue to create a more natural, personalized look than a range of default grays.
*/

/* Nero | Text Color (AAA) */
$nero: rgb(22, 22, 22);

/* Cooled Carbon (AAA) */
$cooledCarbon: rgb(50, 51, 54);

/* Gray 1 (AA) */
$gray1: rgb(86, 89, 99);

/* Gray 2 */
$gray2: rgb(142, 144, 154);

/* Gray 3 */
$gray3: rgb(168, 170, 179);

/* Gray 4 */
$gray4: rgb(201, 203, 213);

/* Gray 5 */
$gray5: rgb(229, 230, 238);

/* Gray 6 */
$gray6: rgb(241, 241, 247);

/* Gray 7 */
$gray7: rgb(248, 248, 253);

/* White */
$white: rgb(255, 255, 255);

/* Semantic Colors
-----------------------------------------------------------------
- Semantic colors help keep users informed. It adopts users’ color associations to communicate state information effectively.
- In certain situations, neutral colors can be used as a substitute for semantic colors to communicate feedback.
- Only the Neutral color meets the WCAG-AA contrast ratio; because of this we can not use any of the other colors for text.

- ^A 90% opacity #FFFFFF layer has been applied to the layer to achieve this color.
  Use as a background to ADA compliant body text.
*/

/* Positive */
$positive: rgb(50, 152, 77);

/* Warning */
$warning: rgb(225, 122, 23);

/* Error */
$error: rgb(205, 31, 31);

/* Neutral (AA) */
$neutral: rgb(10, 91, 186);

/* Positive^ */
$positiveBackground: rgb(234, 244, 237);

/* Warning^ */
$warningBackground: rgb(252, 241, 232);

/* Error^ */
$errorBackground: rgb(250, 233, 233);

/* Neutral^ (AA) */
$neutralBackground: rgb(230, 238, 248);

// stylelint-disable property-case
:export {
  /** Primary Colors */
  teal: $teal;
  deepTeal: $deepTeal;
  ocean: $ocean;
  mulberry: $mulberry;

  /* Secondary Colors */
  dandelion: $dandelion;
  spring: $spring;
  emerald: $emerald;
  cobalt: $cobalt;
  eggplant: $eggplant;

  /* Teal */
  seaFoam: $seaFoam;
  bermuda: $bermuda;
  eden: $eden;

  /* Ocean */
  catskill: $catskill;
  polo: $polo;
  cyprus: $cyprus;

  /* Mulberry */
  prim: $prim;
  viola: $viola;
  port: $port;

  /* Dandelion */
  pearl: $pearl;
  buttermilk: $buttermilk;

  /* Grays */
  nero: $nero;
  cooledCarbon: $cooledCarbon;
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  gray7: $gray7;
  white: $white;

  /* Semantics */
  positive: $positive;
  warning: $warning;
  error: $error;
  neutral: $neutral;
  positiveBackground: $positiveBackground;
  warningBackground: $warningBackground;
  errorBackground: $errorBackground;
  neutralBackground: $neutralBackground;
}
// stylelint-enable property-case

// font weights:
// 100 - unused
// 200 - unused
// 300 - Futura Allele Light
// 400 - Futura Allele Book
// 500 - Futura Allele Medium
// 600 - unused
// 700 - Futura Allele Demibold
// 800 - unused
// 900 - unused

// "Light" weight maps to Futura Allele Light typeface
$font-weight-light: 300;

// "Book" weight maps to Futura Allele Book typeface
// - this is effectively our "regular" text
$font-weight-book: 400;

// "Medium" weight maps to Futura Allele Medium typeface
$font-weight-medium: 500;

// "Demibold" weight maps to Futura Allele Demibold typeface
// - this is effectively our "bold" text
$font-weight-demibold: 700;

// Variable map, if that's your thing
$font-weights: (
  'light': $font-weight-light,
  'book': $font-weight-book,
  'medium': $font-weight-medium,
  'demibold': $font-weight-demibold,
);

/* Transition durations */
$transition-duration-entering: 220ms;
$transition-duration-leaving: 190ms;

// these are the old breakpoints and used solely in the PE transition
// from Stylus to SASS. After the PE migration to SASS is complete,
// the next upgrade will be to use Invitae Design System style guide
// patterns and move to the new breakpoints in @invitae/ids-sass

$xsMin: 350px;
$xsMax: 767px;
$smMin: 768px;
$smMax: 991px;
$mdMin: 992px;
$mdMax: 1199px;
$lgMin: 1200px;

// these are the old colors and used solely in the PE transition
// from Stylus to SASS. After the PE migration to SASS is complete,
// the next upgrade will be to use Invitae Design System style guide
// patterns and move to the new colors in @invitae/ids-sass

// ----------------------------------------------------------
// common - color palette
// ----------------------------------------------------------
// logo colors
$i-color-mountain: #11ada0;
$i-color-bondi: #0888b2;
$i-color-willow2: #a3db70;
$i-color-emerald: #59ce7f;
$i-color-press-release: #1d99b1;

// additional palette colors
$i-color-niagara: #0aaca0;
$i-color-jellybean: #2a979c;
$i-color-matisse: #1971ab;
$i-color-matisse2: #2b6caa;
$i-color-valencia: #dd4343;
$i-color-fuchsia: #8e2666;
$i-color-crimson: #cd1f1f;
$i-color-magenta: #8e2666;
$i-color-warning: #e17a17;
$i-color-willow: #a3cf71;
$i-color-fern2: #5bbd71;
$i-color-chateau: #44a55a;
$i-color-laurel: #009900;
$i-color-blue: #1356a0;
$i-color-bay: #273b88;
$i-color-jacarta: #2c2f72;
$i-color-disco: #991f57;
$i-color-purple: #771d59;
$i-color-grape: #52215e;
$i-color-red-base: #d94848;
$i-color-alto: #e0e0e0;
$i-color-positive: #32984d;
$i-color-emerald-base: #5fb777;
$i-color-neutral: #e6eef8;

// grayscale
$i-color-cotton: #efedea;
$i-color-cararra2: #e8e6e2;
$i-color-concrete: #f2f2f2;
$i-color-timberwolf: #d6d4d0;
$i-color-tide: #bbb7b2;
$i-color-zorba: #9b948e;
$i-color-soot: #161616;
$i-color-black: #000000;
$i-color-pampas: #f6f5f3;
$i-color-cararra: #f4f3f0;
$i-color-silver: #cccccc;
$i-color-tapa: #76716b;
$i-color-tundora: #464646;

// ----------------------------------------------------------
// DSM colors
// ----------------------------------------------------------
// primary colors
$i-color-teal-lighten-92: #ecf8f7;
$i-color-teal-lighten-45: #80d1ca;
$i-color-teal: #00b0a0;
$i-color-teal-1: #009688;
$i-color-teal-base: #19ac9f;
$i-color-teal-darken-40: #128279;
$i-color-teal-darken-80: #0c4851;

$i-color-blue-lighten-91: #ddf3f0;
$i-color-blue-lighten-92: #eef3f8;
$i-color-blue-lighten-45: #8aaed0;
$i-color-blue-1: #306297;
$i-color-blue-base: #2c6baa;
$i-color-blue-darken-40: #194066;

// secondary colors
$i-color-spring-base: #9bca6e;
$i-color-emerald-base: #5fb777;

// grays
$i-color-carbon-light: #666666;
$i-color-carbon-medium: #4a4a4a;
$i-color-carbon: #373737;
$i-color-gray-whitesmoke: #f1f1f1;

$i-color-gray: #828080;
$i-color-gray-1: #565963;
$i-color-gray-2: #8e909a;
$i-color-gray-3: #a8aab3;
$i-color-gray-4: #d2d4dc;
$i-color-gray-5: #e5e6ee;
$i-color-gray-6: #f1f1f7;
$i-color-gray-7: #f8f8fd;
$i-color-gray-8: #76757e;
$i-color-gray-9: #8e92a4;
$i-color-gray-10: #ebfaf8;
$i-color-alto: #e0e0e0;
$i-color-zorba2: #9fa0a8;
$i-color-silver: #cccccc;
$i-color-react-select-border-color: #eaeaea;
$i-color-gray-11: #f9f9f9;
$i-color-gray-12: #f1f1f1;
$i-color-gray-13: #dadada;
$i-color-gray-14: #c6c5c5;

// semantic colors
$i-color-red-semantic: #d94848;
$i-color-orange-semantic: #f58c45;
$i-color-emerald-semantic: #66bf7e;

$i-color-tabs-arrow-box-shadow: rgba(255, 255, 255, 0.75);

$i-color-toggle-bg-active: #65b98d;
$i-color-positive: #32984d;

$i-color-warning-container-box: #f7f7fb;
$i-color-warning-container-warning: #f96400;

$i-color-react-select-border-color: #eaeaea;

$i-color-dashboard-anchor: #09ac9f;
$i-color-dashboard-border-light: #e4e2dd;
$i-color-dashboard-note: #7c7b86;

$partnership-background-color: rgb(250, 246, 249);

@mixin gradient-icon($start, $stop) {
  background: -webkit-linear-gradient($start, $stop);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* stylelint-disable */
@mixin hdpi-background($url, $size: cover) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: $url;
    background-size: $size;
  }
}

@mixin disabledField() {
  border-color: $i-color-gray-3 !important;
  background-color: $i-color-gray-7 !important;
  cursor: default !important;
}
/* stylelint-enable */

@mixin button() {
  min-height: 32px;
  padding: 6px 10px 4px;
  border-radius: 4px;
  border: 1px solid;
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
}

@mixin label() {
  color: $i-color-gray-1;
  font-size: 14px;
  line-height: 1.28;
  letter-spacing: 0.38px;
  text-transform: uppercase;
  font-weight: $font-weight-demibold;
}

@mixin field() {
  display: block;
  min-height: 38px;
  width: 100%;
  padding: 10px;
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $i-color-gray-3;
  font-size: 16px;
  line-height: 1;
  transition: linear all $transition-duration-entering;

  &:focus {
    border-color: $i-color-niagara;
  }

  &.error {
    border-color: $i-color-crimson;
  }
}

/* stylelint-disable selector-class-pattern*/
.react-datepicker__header {
  background-color: $white;
  border-block-end: none;
  padding: 0;
  padding-block-start: 16px;
}

.react-datepicker__navigation {
  inset-block-start: 8px;
}

.react-datepicker__navigation--previous {
  inset-inline-start: 8px;
}

.react-datepicker__navigation--next {
  inset-inline-end: 8px;
}

.react-datepicker__current-month,
.react-datepicker__day-names {
  margin-block-end: 16px;
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day-names {
  margin-inline: 6px;
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day-name {
  margin-inline: 9px;
}

.react-datepicker__day--in-selecting-range {
  background-color: $polo;
  border-radius: 50%;
}

.react-datepicker__day--selected,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  background-color: $ocean;
  border-radius: 50%;
}

div.react-datepicker__day--outside-month {
  background-color: transparent;
  color: transparent;
}

.react-datepicker__day--keyboard-selected {
  color: $nero;
}

.react-datepicker__day--today {
  font-weight: $font-weight-book;
}

.nv-calendar-day.react-datepicker__day--keyboard-selected {
  color: $white;
}

/**
  react-datepicker uses `REM` to set fontSizes and some projects are having different sizes related to that
  so, we are fixing fontSizes here with `PX`
  start ->
**/
div.react-datepicker__day-name,
div.react-datepicker__day {
  font-size: 13px;
}

.react-datepicker__current-month {
  font-size: 15px;
}

/** <- end **/

/* stylelint-enable selector-class-pattern */
