/* stylelint-disable plugins/declaration-use-variable */
@import url('https://casa.invitae.com/dist/fonts/invitae-web/2/invitae-web.css');

@font-face {
  font-family: ScalaSansWeb;
  font-display: swap;
  src: url('https://casa.invitae.com/dist/fonts/scala-sans-web/1/ScalaSansWeb.woff') format('woff');
}

@font-face {
  font-family: ScalaSansWeb;
  font-display: swap;
  src: url('https://casa.invitae.com/dist/fonts/scala-sans-web/1/ScalaSansWeb-Ita.woff') format('woff');
  font-style: italic;
}

@font-face {
  font-family: ScalaSansWeb;
  font-display: swap;
  src: url('https://casa.invitae.com/dist/fonts/scala-sans-web/1/ScalaSansWeb-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: ScalaSansWeb;
  font-display: swap;
  src: url('https://casa.invitae.com/dist/fonts/scala-sans-web/1/ScalaSansWeb-BoldIta.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

body .embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #8e2666;
  font-family: 'Arial', sans-serif;
}

body .embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #8e2666;
}

/* stylelint-enable plugins/declaration-use-variable */
